<template>
  <form @submit.prevent="createNewLicense" class="keygen">
    <p class="keygen__warning" v-if="seatLimitReached">
      <b>You've reached the seat limit for your enterprise plan.</b> Please
      disable any unused license keys (by disabling any <em>Active</em> toggles
      below), or
      <a href="https://hypermatic.com/contact" target="_blank">contact us</a>
      for additional seats.
    </p>

    <div class="keygen__header">
      <h3 class="keygen__heading">Create New License Key</h3>
      <p class="keygen__description">
        <b>{{ totalSeats - activeSeats }}/{{ totalSeats }}</b> Seats Available
      </p>
    </div>

    <label for="emailInput" class="login__label">Email Address </label>

    <input
      id="emailInput"
      name="emailInput"
      class="login__input login__input--email"
      :placeholder="placeholderEmail"
      type="email"
      multiple
      v-model="emailAddress"
      required
    />

    <label for="emailInput" class="login__label"
      >(Pro Tip: Use comma seperated emails to add multiple keys at once)</label
    >

    <ul v-if="emailAddress != '' && emailAddresses.length">
      <li v-for="(email, i) in emailAddresses" :key="email + i">
        {{ email }}
      </li>
    </ul>

    <button
      type="submit"
      :disabled="!emailAddress || seatLimitReached"
      class="login__button"
    >
      <span v-if="emailAddress != '' && emailAddresses.length"
        >Create New License Keys ({{ emailAddresses.length }})</span
      >
      <span v-else>Create New License Key</span>
    </button>
  </form>
</template>

<script>
export default {
  name: "Keygen",
  props: {
    activeSeats: Number,
    totalSeats: Number,
    domain: String
  },
  data() {
    return {
      emailAddress: ""
    };
  },
  methods: {
    createNewLicense() {
      const emailsToAdd = this.emailAddresses.splice(0, this.seatsRemaining);
      if (!this.seatLimitReached) {
        this.$emit("create", emailsToAdd);
        this.emailAddress = "";
      }
    }
  },
  computed: {
    emailAddresses() {
      const emails = this.emailAddress.split(",");
      return emails;
    },
    placeholderEmail() {
      return `eg. name@${this.domain}`;
    },
    seatsRemaining() {
      return this.totalSeats - this.activeSeats;
    },
    seatLimitReached() {
      return this.activeSeats >= this.totalSeats;
    }
  }
};
</script>

<style scoped lang="scss">
.keygen__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keygen__heading {
  margin: 0;
}

.keygen__description {
  font-size: 14px;
  text-align: right;
  margin: 0;
}

.keygen__warning {
  padding: 16px;
  border-radius: 4px;
  border: 2px solid orange;
  background: rgba(255, 166, 0, 0.245);
  margin-top: 0;
}
</style>
