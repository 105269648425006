<template>
  <div class="licenses">
    <div class="licenses__header">
      <h3 class="licenses__heading">Enterprise License Keys</h3>
      <p class="licenses__description">
        <!-- <b>{{ activeSeats }}/{{ totalSeats }}</b> Allocated Seats Active -->
      </p>
    </div>
    <div class="licenses__search">
      <div class="licenses__form">
        <label class="login__label">Search for email or license key</label>
        <input
          class="login__input login__input--search"
          :placeholder="searchPlaceholder"
          v-model="searchTerm"
        />
      </div>
      <div>
        <div class="licenses__header">
          <label class="switch"
            ><input
              @input="onlyShowActive = !onlyShowActive"
              :checked="onlyShowActive"
              type="checkbox"
            />
            <div></div>
          </label>
          <label class="login__label login__label--toggle"
            >Only show active licenses</label
          >
        </div>
      </div>
    </div>

    <table class="licenses__table">
      <thead>
        <tr>
          <th class="licenses__toggle">Active</th>
          <th>Email Address</th>
          <th class="licenses__key">License Key</th>
          <th class="licenses__copy"></th>
        </tr>
      </thead>
      <tbody v-if="filteredLicenses.length">
        <tr v-for="license in filteredLicenses" :key="license.id">
          <td class="toggle table__cell table__cell--toggle">
            <label
              :class="[
                license.active
                  ? 'switch switch--active'
                  : 'switch switch--inactive',
                !license.active && seatLimitReached
                  ? 'switch switch--disabled'
                  : 'switch switch--enabled'
              ]"
              ><input
                @input="handleActiveToggle(license)"
                :checked="license.active"
                type="checkbox"
                :disabled="!license.active && seatLimitReached"
              />
              <div></div>
            </label>
          </td>
          <td
            v-html="highlightMatches(license.email)"
            :class="
              !license.active
                ? 'table__cell table__cell--inactive'
                : 'table__cell'
            "
          ></td>
          <td
            v-html="highlightMatches(license.id)"
            :class="
              !license.active
                ? 'table__cell table__cell--key table__cell--inactive'
                : 'table__cell table__cell--key'
            "
          ></td>
          <td v-if="!license.active">
            <button
              class="licenses__button licenses__button--destroy"
              @click="handleDeleteKey(license)"
            >
              <span>Delete&nbsp;Key</span>
            </button>
          </td>
          <td
            v-else
            :class="
              !license.active
                ? 'table__cell table__cell--copy table__cell--inactive'
                : 'table__cell table__cell--copy'
            "
          >
            <button
              class="licenses__button"
              @click="handleCopy(license.id)"
              :disabled="!license.active"
            >
              <span v-if="copied == license.id">Copied!</span>
              <span v-else>Copy&nbsp;Key</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";

export default {
  name: "Table",
  props: {
    licenses: Array,
    activeSeats: Number,
    totalSeats: Number,
    activeSubscription: Object,
    seatLimitReached: Boolean,
    domain: String
  },
  data() {
    return {
      copied: "",
      onlyShowActive: false,
      searchTerm: ""
    };
  },
  computed: {
    filteredLicenses() {
      return this.licenses.filter(license => {
        const email = license.email.toString().toLowerCase();
        const licenseKey = license.id.toLowerCase();
        const searchTerm = this.searchTerm.toLowerCase();

        if ((!license.active && this.onlyShowActive) || license.archived) {
          return false;
        }

        return licenseKey.includes(searchTerm) || email.includes(searchTerm);
      });
    },
    searchPlaceholder() {
      if (!this.licenses.length) {
        return `eg. "name@${this.domain}"`;
      }
      return `eg. "${this.licenses[0].email}"`;
    }
  },
  methods: {
    // getProductName(permalink) {
    //   if (permalink) {
    //     return "Pro";
    //   }
    //   return "Pro Bundle";
    // },
    highlightMatches(text) {
      if (!text) return "";
      const matchExists = text
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
      if (!matchExists) return text;
      const re = new RegExp(this.searchTerm, "ig");
      return text.replace(re, matchedText => `<b>${matchedText}</b>`);
    },
    handleCopy(key) {
      this.copied = key;
      copy(key);
    },
    handleActiveToggle(license) {
      const { id, active } = license;
      if (!active && this.seatLimitReached) {
        // Do nothing
      } else {
        this.$emit("toggle", id);
      }
    },
    handleDeleteKey(license) {
      const { id, email } = license;
      if (
        confirm(
          `Are you sure you want to permanently delete this key/seat for ${email} from your license?`
        ) == true
      ) {
        this.$emit("delete", id);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.licenses__search,
.licenses__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.licenses__form {
  width: 44%;
}

.login__label--toggle {
  margin: 0;
  margin-left: 8px;
  margin-top: 3px;
}

.licenses__search {
  margin-bottom: 16px;
}

.licenses__heading {
  margin: 0;
  // margin-bottom: 16px;
}

.licenses__description {
  font-size: 14px;
  text-align: right;
  margin: 0;
}

.login__input--search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z'/%3E%3C/svg%3E") !important;
}

.licenses__table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
}

tbody tr:hover {
  background-color: #eee;
}

tr:nth-child(even) {
  // background-color: rgba(#fff, 0.2);
}

th,
td {
  text-align: left;
  padding: 4px 16px;
  // color: #fff;
}

th {
  color: #666;
  font-size: 14px;
}

td {
  border-top: 1px solid #eee;
}

.table__cell--inactive {
  color: #999;
  text-decoration: line-through;
}

.licenses__key,
.table__cell--key {
  width: 380px;
}

.licenses__toggle,
.table__cell--toggle {
  width: 90px;
}

.licenses__copy,
.table__cell--copy {
  width: 120px;
}

.switch input {
  position: absolute;
  opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
  margin-top: 3px;
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 1em;
  width: 2em;
  background: #2f3d4c;
  border-radius: 1em;
  cursor: pointer;
}

.switch--inactive {
  background: #ccc;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 220ms;
  -moz-transition: all 220ms;
  transition: all 220ms;
  cursor: pointer;
}

.switch--enabled {
  cursor: pointer;
}

.switch--disabled {
  cursor: not-allowed;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.licenses__button {
  appearance: none;
  width: 100%;
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #2f3d4c;
  box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  transition: all 300ms ease;
  display: block;
  text-align: center;
  padding: 8px 16px;

  &:focus,
  &:hover {
    background-color: lighten(#2f3d4c, 5%);
    box-shadow: 0 4px 6px rgba(#2f3d4c, 0.25);
  }

  &:active {
    background-color: #2f3d4c;
    box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover {
      background-color: #2f3d4c;
      opacity: 0.3;
      cursor: not-allowed;
      box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
    }
  }
}

.licenses__button--destroy {
  background-color: white;
  color: red;
  border: 1px solid red;

  &:focus,
  &:hover {
    background-color: red;
    color: white;
  }

  &:active {
    background-color: red;
    color: white;
  }
}
</style>
