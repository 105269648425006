<template>
  <div class="matrix">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      class="matrix__top"
    >
      <polygon points="0,100 100,0 100,100" />
    </svg>
    <canvas class="matrix"></canvas>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      class="matrix__bottom"
    >
      <polygon points="0,100 100,0 100,100" />
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  name: "Matrix",
  mounted() {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        canvas.width = window.innerWidth * 1.5;
        canvas.height = window.innerHeight * 1.5;

        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZあいうえおかきぐげござじずぜぞだぢでどなにぬねのはばぱひびぴふぶぷほみむめもゃやゅゆょよらりるれろゎわゐゑをんゔゕゖ゚゛゜ゝゞゟ゠ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺ・ーヽヾヿ";

        const letters = characters.split("");

        const fontSize = 12;
        const columns = canvas.width / fontSize;

        let drops = [];
        for (let i = 0; i < columns; i++) {
          drops[i] = 1;
        }

        setInterval(() => {
          if (canvas && ctx) {
            ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          for (let i = 0; i < drops.length; i++) {
            if (canvas && ctx) {
              let text = letters[Math.floor(Math.random() * letters.length)];
              ctx.fillStyle = "#000";
              ctx.fillText(text, i * fontSize, drops[i] * fontSize);
              drops[i]++;
              if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
                drops[i] = 0;
              }
            }
          }
        }, 33);
      }
    }
  }
};
</script>

<style lang="scss">

.matrix {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.matrix {
  object-fit: cover;
}
.matrix__top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40vh;
  z-index: 1;
  transform: scale(-1);
  fill: #fff;
  /* set height to pixels if you want angle to change with screen width */
}
.matrix__bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40vh;
  z-index: 1;
  fill: #fff;
  /* set height to pixels if you want angle to change with screen width */
}
</style>
