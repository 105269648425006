import Vue from "vue";
import VueRouter from "vue-router";
import Licenses from "../views/Licenses.vue";
// import Companies from '../views/Companies.vue'
// import Admins from '../views/Admins.vue'
import Subscriptions from "../views/Subscriptions.vue";
import Home from "../views/Home.vue";
import Firefoo from "../views/Firefoo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  // {
  //   path: '/admin',
  //   name: 'companies',
  //   component: Companies
  // },
  {
    path: "/enterprise/:orgId",
    name: "enterprise",
    component: Subscriptions
  },
  {
    path: "/enterprise/:orgId/:permalinkId",
    name: "licenses",
    component: Licenses
  },
  {
    path: "/firefoo",
    name: "firefoo",
    component: Firefoo
  },
  {
    path: "/firefoo/:licenseKey",
    name: "firefoo",
    component: Firefoo
  }
  // {
  //   path: '/admin/company/:orgId',
  //   name: 'admins',
  //   component: Admins
  // },

  // {
  //   path: '/godmode',
  //   name: 'godmode',
  //   component: Godmode
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
