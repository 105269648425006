<template>
  <div class="home">
    <Spinner v-if="user.uid && loading" class="spinning" />
    <div v-if="(!user.uid || document.isDeleted == true) && !loading">
      <Login
        title="Hypermatic Enterprise Login"
        :error="error"
        :deleted="document.isDeleted"
        @auth="handleLogin"
        @logout="handleLogout"
        @error="handleError"
      />
    </div>
    <div v-else-if="!loading && user.uid">
      <Header
        @logout="handleLogout"
        :document="document"
        :org="document.org"
        :name="document.name"
        logo=""
        :private="false"
      />

      <div class="home__content home__content--primary">
        <Keygen
          @create="createNewLicense"
          :activeSeats="activeLicenses.length"
          :totalSeats="activeSubscription.seats"
          :domain="document.domain"
        />
      </div>
      <div class="home__content home__content--secondary">
        <Users
          @toggle="handleActiveToggle"
          @delete="handleDeletingKey"
          :activeSubscription="activeSubscription"
          :activeSeats="activeLicenses.length"
          :totalSeats="licenses.length"
          :licenses="licenses"
          :domain="document.domain"
          :seatLimitReached="seatLimitReached"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import Spinner from "@/components/Spinner.vue";
import Header from "@/components/Header.vue";
import Keygen from "@/components/Keygen.vue";
import Users from "@/components/Users.vue";
import firebase from "firebase/app";
import crypto from "crypto-browserify";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import { db } from "../db";

// const db = firebase.firestore();
// const storageRef = firebase.storage().ref();

export default {
  name: "Home",
  components: {
    Login,
    Spinner,
    Header,
    Keygen,
    Users
  },
  mounted() {
    // Example Gumroad Key: 2724E727-BB0C462B-88D973D6-CCAEACF8

    // adam@figmatic.com
    // UID: hLswwIyXoaaPBfolWSAKCh9Sbiu2
    // console.log("preauth");
    firebase.auth().onAuthStateChanged(async user => {
      // console.log("auth");
      // document.title = "Loading...";
      if (user) {
        // if (
        //   user.email !== `crypto_${this.$route.params.userId}@figmaticapp.com`
        // ) {
        //   firebase.auth().signOut();
        // } else {

        this.user = user;
        const doc = await this.fetchDocument();
        await this.fetchLicenses();
        // console.log(doc);
        // console.log(licenses);
        this.document = doc;
        this.loading = false;
        // }
      } else {
        document.title = "Hypermatic Enterprise Login";
        this.user = {};
        this.loading = false;
      }
    });
  },
  data() {
    return {
      newEmail: "",
      loading: true,
      licenses: [],
      document: { isDeleted: false },
      user: {},
      error: "",
      docId: this.$route.params.docId
    };
  },
  // firestore: {
  //   licenses: firebase
  //     .firestore()
  //     .collection(`enterprise_users`)
  //     .doc(this.user.uid)
  //     .collection(`licenses`),
  // },
  computed: {
    seatLimitReached() {
      return this.activeLicenses.length >= this.activeSubscription.seats;
    },
    activeSubscription() {
      const sub = this.document.subscriptions.find(
        subscription => subscription.permalink === this.permalinkId
      );
      return sub;
    },
    orgId() {
      return this.$route.params.orgId;
    },
    permalinkId() {
      return this.$route.params.permalinkId;
    },
    activeLicenses() {
      return this.licenses.filter(
        license => license.active && !license.archived
      );
    }
  },
  methods: {
    async createNewLicense(emails) {
      // check that key doesn't exist?
      for (const email of emails) {
        const emailExists = this.licenses.find(
          l => l.email.toLowerCase() === email.toLowerCase()
        );
        if (
          !this.seatLimitReached &&
          email !== "" &&
          emailExists === undefined
        ) {
          const documentKey = crypto
            .randomBytes(16)
            .toString("hex")
            .split(/(.{8})/)
            .filter(Boolean)
            .join("-")
            .toUpperCase();

          const { permalink, key } = this.activeSubscription;
          const { slug } = this.document;

          const license = {
            active: true,
            archived: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            email,
            key,
            lastModifed: firebase.firestore.FieldValue.serverTimestamp(),
            org: slug,
            permalink
          };

          await firebase
            .firestore()
            .collection(`enterprise_keys`)
            .doc(documentKey)
            .set(license);
        }
      }
    },
    async handleDeletingKey(id) {
      // "Archives" the key and removes it from the license list table UI
      return await firebase
        .firestore()
        .collection(`enterprise_keys`)
        .doc(id)
        .update({
          active: false,
          archived: true,
          lastModifed: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          // console.log("user updated!");
        });
    },
    async handleActiveToggle(id) {
      const active = this.licenses.find(license => license.id === id).active;
      // console.log(this.licenses);
      // console.log(id);
      // console.log(active);

      // const orgId = this.$route.params.orgId;

      return await firebase
        .firestore()
        .collection(`enterprise_keys`)
        .doc(id)
        .update({
          active: !active,
          lastModifed: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          // console.log("user updated!");
        });
    },
    async fetchLicenses() {
      // console.log("this.permalinkId", this.permalinkId);
      // console.log("this.orgId", this.orgId);

      this.$bind(
        "licenses",
        firebase
          .firestore()
          .collection("enterprise_keys")
          .where("org", "==", this.orgId)
          .where("permalink", "==", this.permalinkId)
      );
    },
    async fetchDocument() {
      // console.log("userId", userId);

      let snapshot = await firebase
        .firestore()
        .collection("enterprise_users")
        .doc(this.orgId)
        .get()
        .then(doc => {
          return { id: doc.id, ...doc.data() };
        });

      return snapshot;
    },
    async handleLogin(authenticated) {
      // console.log(authenticated.user);
      this.user = authenticated.user;
      // const id = this.$route.params.orgId
      //   ? this.$route.params.orgId
      //   : this.user.uid;
      const doc = await this.fetchDocument();
      await this.fetchLicenses();
      // console.log(licenses);
      // console.log(doc);
      this.document = doc;
    },
    handleLogout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          this.user = {};
        })
        .catch(() => {
          // console.log(error);
          // An error happened.
        });
    },
    handleError(error) {
      this.user = {};
      switch (error.code) {
        case "auth/wrong-password":
          this.error = `Oops! That password isn't correct.`;
          break;
        case "auth/user-not-found":
          this.error = `Oops! This user wasn't found.`;
          break;
        default:
          this.error = error.message;
      }
    }
  }
  // methods: {
  //   async fetchDocument(userId) {
  //     let snapshot = await db
  //       .collection("enterprise_users")
  //       .doc(userId)
  //       .get()
  //       .then((doc) => {
  //         return { id: doc.id, ...doc.data() };
  //       });
  //     return snapshot;
  //   },
  // },
};
</script>

<style lang="scss">
html {
  // background: linear-gradient(45deg, #e978a0, #51b4db);
  // background-repeat: no-repeat;
  // background-position: fixed;
  min-height: 100%;
  background-color: #fcfcfc;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='28'%3E%3Cpath fill='%23ccc' fill-opacity='.1' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 10-4 0h-4.09A25.98 25.98 0 000 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 00-2-2v-2a4 4 0 013.98 3.6 28.09 28.09 0 012.8-3.86A8 8 0 000 6V4a9.99 9.99 0 018.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 000 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0014.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 014 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 00-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0148.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0156 4v2a8 8 0 00-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0156 10v2a2 2 0 00-2 2.07 28.4 28.4 0 012-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 012.8-3.86 8 8 0 00-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 017.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 00-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0136.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 00-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0125.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 00-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0116.34 0z'/%3E%3C/svg%3E");
  background-size: 80px;
}

body {
  background: none;
}

.home {
  padding-top: 82px;
}

@keyframes slideUp {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.home__content {
  animation: slideUp 600ms 300ms ease both;
  max-width: 500px;
  padding: 24px;
  margin: 44px auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.home__content--secondary {
  animation-delay: 370ms;
  max-width: 1000px;
}
</style>
