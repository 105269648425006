import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { firestorePlugin } from "vuefire";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css' //Vuesax styles
// Vue.use(Vuesax, {})

// Vue.use(firestorePlugin);

Vue.config.productionTip = false;

const configOptions = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

firebase.initializeApp(configOptions);

Vue.use(firebase);

Vue.use(firestorePlugin);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
