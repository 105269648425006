<template>
  <div class="home">
    <Spinner v-if="user.uid && loading" class="spinning" />
    <div v-if="!user.uid && !loading">
      <Login
        title="Hypermatic Enterprise Login"
        :error="error"
        :deleted="false"
        @auth="handleLogin"
        @logout="handleLogout"
        @error="handleError"
      />
    </div>
    <div v-else-if="!loading && user.uid">
      <Header
        @logout="handleLogout"
        :document="document"
        :org="document.org"
        :name="document.name"
        :noLinks="true"
      />

      <!-- <button @click="exportOverusedSeats">
          Download Overused Seats CSV
        </button> -->

      <!-- <button @click="bulkSyncGumroadSeatsWithFirebase">
        Sync All Gumroad Seat Counts with Firebase
      </button> -->

      <div class="firefoo__content firefoo__content--search">
        <div class="licenses__search">
          <h5 class="firefoo__heading firefoo__heading--search">
            Search Gumroad

            <a
              class="firefoo__copy"
              href="/firefoo/BB09A49C-278240A5-BB919488-F375227F"
            >
              License Key (Pixelay Test Example)</a
            >

            or

            <a class="firefoo__copy" href="/firefoo/789008748133784695">
              Figma User ID (Hypermatic Example)
            </a>
          </h5>
          <form @submit.prevent="searchByKeyword">
            <input
              class="login__input login__input--search"
              placeholder="eg. BB09A49C-278240A5-BB919488-F375227F or 789008748133784695"
              v-model="searchTerm"
              :disabled="loadingResults"
            />
            <button
              type="submit"
              :disabled="!searchTerm || loadingResults"
              class="login__button"
            >
              <span v-if="loadingResults && displayFigmaUserData"
                >Searching for Figma User...</span
              >
              <span v-else-if="loadingResults"
                >Searching for Gumroad License...</span
              >
              <span v-else>Search</span>
            </button>
          </form>
        </div>
      </div>

      <div class="firefoo__wrapper">
        <div
          v-if="licenses.length && displayGumroadLicenseData"
          class="firefoo__content firefoo__content--secondary"
        >
          <div class="firefoo__header">
            <h3 class="firefoo__heading">
              License Overview
            </h3>
          </div>

          <div v-for="license in licenses" :key="license.id">
            <h3
              v-if="
                !loadingResults &&
                  (license.disabled ||
                    !gumroadData.success ||
                    (gumroadData.message &&
                      gumroadData.message.includes('disabled')))
              "
            >
              License is disabled
            </h3>
            <table v-else class="firefoo__table">
              <thead>
                <tr>
                  <th>Plugin</th>

                  <th>Seats on License (Firebase)</th>
                  <th>Email</th>
                  <th>Key</th>
                  <th>Key Disabled</th>
                  <!-- <th>ID</th> -->
                  <!-- <th>Subscription ID</th> -->
                  <!-- <th>Permalink</th> -->
                  <!-- <th>Tier</th> -->
                  <!-- <th>Multiple Seats</th> -->
                  <th>Test Key</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody v-if="!loadingResults">
                <tr>
                  <td class="table__cell">{{ license.product }}</td>

                  <td class="table__cell">
                    <b
                      v-if="
                        license.tier === 'pro' &&
                          license.seats != gumroadData.purchase.quantity
                      "
                      style="color: red;"
                      >{{ license.seats }} (Mismatched)</b
                    >
                    <b v-else-if="license.tier != 'pro'"
                      >{{ license.seats }} ({{ license.tier }})</b
                    >
                    <b v-else>{{ license.seats }}</b
                    ><br />

                    <button
                      :disabled="
                        license.tier != 'pro' ||
                          license.seats == gumroadData.purchase.quantity
                      "
                      @click="
                        handleSeatCountSync({
                          key: license.key,
                          firebaseSeats: license.seats,
                          gumroadSeats: gumroadData.purchase.quantity
                        })
                      "
                      class="firefoo__button"
                    >
                      <span>Sync Gumroad Seat Count</span>
                    </button>

                    <input
                      v-if="license.tier != 'pro'"
                      v-model="customNewSeatCount"
                      type="number"
                      class="login__input login__input--trials"
                      :min="1"
                      :max="50"
                      :placeholder="gumroadData.purchase.quantity"
                    />
                    <button
                      v-if="license.tier != 'pro'"
                      :disabled="!customNewSeatCount"
                      @click="
                        handleSeatCountSync({
                          key: license.key,
                          firebaseSeats: license.seats,
                          gumroadSeats: customNewSeatCount
                        })
                      "
                      class="firefoo__button"
                    >
                      <span>Update With Custom Seat Count</span>
                    </button>
                  </td>
                  <td class="table__cell">
                    <b>{{ license.email }}</b
                    ><br />
                    <button
                      @click="handleCopy(license.email)"
                      class="firefoo__button"
                    >
                      <span v-if="copied == license.email">Copied Email!</span
                      ><span v-else>Copy Email</span>
                    </button>
                  </td>

                  <td class="table__cell">
                    {{ license.key }}<br />
                    <button
                      @click="handleCopy(license.id)"
                      class="firefoo__button"
                    >
                      <span v-if="copied == license.id">Copied Key!</span
                      ><span v-else>Copy Key</span>
                    </button>
                  </td>
                  <td class="table__cell" v-if="license.disabled">Yes</td>
                  <td v-else class="table__cell">No</td>
                  <!-- <td class="table__cell">{{ license.id }}</td> -->
                  <!-- <td class="table__cell">{{ license.subscription_id }}</td> -->
                  <!-- <td class="table__cell">{{ license.permalink }}</td> -->
                  <!-- <td class="table__cell">{{ license.tier }}</td> -->
                  <!-- <td class="table__cell">{{ license.multiple_seats }}</td> -->
                  <td class="table__cell">
                    <span v-if="license.test">Yes</span><span v-else>No</span>
                  </td>
                  <td class="table__cell">{{ license.country }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          v-if="licenses.length && displayGumroadLicenseData"
          class="firefoo__content firefoo__content--secondary"
        >
          <div class="firefoo__header">
            <h3 class="firefoo__heading">
              License Status (Gumroad API)
            </h3>
          </div>

          <table class="firefoo__table">
            <thead>
              <tr>
                <th>Quantity (Gumroad Purchased)</th>
                <!-- <th>Referrer</th> -->
                <th>Original Sale Date</th>
                <!-- <th>Full Name</th> -->
                <th>Discount</th>
                <th>Recurrence</th>
                <th>Refunded</th>
                <th>Disputed</th>
                <th>Ended At</th>
                <th>Cancelled At</th>
                <th>Failed At</th>
                <th>Uses</th>
              </tr>
            </thead>
            <tbody v-if="!loadingResults && gumroadData.success == false">
              <tr>
                <td colspan="9">{{ gumroadData.message }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="!loadingResults">
              <tr>
                <td>{{ gumroadData.purchase.quantity }}</td>
                <!-- <td>{{ gumroadData.purchase.referrer }}</td> -->
                <td>
                  {{ formatTimestamp(gumroadData.purchase.sale_timestamp) }}
                </td>
                <!-- <td>{{ gumroadData.purchase.full_name }}</td> -->
                <td v-if="gumroadData.purchase.offer_code">
                  {{ gumroadData.purchase.offer_code.code }} ({{
                    gumroadData.purchase.offer_code.displayed_amount_off
                  }})
                </td>
                <td v-else>
                  No Discount
                </td>
                <td v-if="gumroadData.purchase.recurrence == 'monthly'">
                  Monthly Billing
                </td>
                <td v-else>Yearly Billing</td>
                <td v-if="gumroadData.purchase.refunded">Yes</td>
                <td v-else>No</td>
                <td v-if="gumroadData.purchase.disputed">Yes</td>
                <td v-else>No</td>
                <td v-if="gumroadData.purchase.subscription_ended_at">
                  {{ gumroadData.purchase.subscription_ended_at }}
                </td>
                <td v-else>Still Active</td>
                <td v-if="gumroadData.purchase.subscription_cancelled_at">
                  {{ gumroadData.purchase.subscription_cancelled_at }}
                </td>
                <td v-else>Still Active</td>
                <td v-if="gumroadData.purchase.subscription_failed_at">
                  {{ gumroadData.purchase.subscription_failed_at }}
                </td>
                <td v-else>Still Active</td>
                <td>{{ gumroadData.uses }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div
          v-if="
            !loadingResults &&
              !users.length &&
              (displayGumroadLicenseData || displayFigmaUserData)
          "
          class="firefoo__content firefoo__content--secondary"
        >
          <h3>
            No Figma Users are using to this License Key yet
          </h3>
        </div> -->

        <div
          v-if="
            users.length && (displayGumroadLicenseData || displayFigmaUserData)
          "
          class="firefoo__content firefoo__content--secondary"
        >
          <div v-for="license in licenses" :key="`user-${license.id}`">
            <div class="firefoo__header">
              <h3 v-if="displayFigmaUserData" class="firefoo__heading">
                Figma User
              </h3>
              <h3 v-else-if="loadingResults" class="firefoo__heading">
                Active Figma Users
              </h3>
              <h3 v-else-if="users.length && license" class="firefoo__heading">
                Active Figma Users ({{ users.length }}/{{ license.seats }}
                Seats Used)
              </h3>
              <h3 v-else class="firefoo__heading">
                No Figma Users on This License Yet
              </h3>

              <div v-if="displayGumroadLicenseData">
                <button
                  class="firefoo__button firefoo__button--destroy"
                  @click="removeUserSeats(license.key)"
                  :disabled="!checkedUsersToDelete.length"
                >
                  <span
                    >Remove Users from License ({{
                      checkedUsersToDelete.length
                    }}
                    Selected)</span
                  >
                </button>
              </div>

              <div v-if="displayFigmaUserData">
                <input
                  class="login__input login__input--trials"
                  placeholder="eg. 10"
                  type="number"
                  v-model="newTrialCount"
                  min="0"
                  max="10"
                />
              </div>
              <button @click="copyUsersList(users)" class="firefoo__button">
                <span v-if="copied == 'usersList'"
                  >Copied Bullet List of Users!</span
                ><span v-else>Copy Bullet List of Users</span>
              </button>
            </div>

            <table class="firefoo__table">
              <thead>
                <tr>
                  <th v-if="displayGumroadLicenseData">Remove?</th>
                  <th>Figma Name</th>
                  <th>Figma User ID</th>
                  <th>User First Created At</th>
                  <th>Last Seen In Figma</th>
                  <!-- <th>Plugin Key</th> -->
                  <!-- <th>Last Used</th> -->
                  <th>Plugins</th>
                  <!-- <th>Trials Left</th> -->
                  <!-- <th v-if="displayGumroadLicenseData">Free Up Seat</th> -->
                </tr>
              </thead>
              <tbody v-if="loadingResults">
                <td colspan="7">Loading Users...</td>
              </tbody>

              <tbody v-else-if="users.length">
                <tr v-for="user in users" :key="user.user.id">
                  <td
                    v-if="displayGumroadLicenseData"
                    class="table__cell table__cell--user"
                  >
                    <input type="checkbox" v-model="user.user.checked" />
                  </td>
                  <td class="table__cell table__cell--user">
                    <b>{{ user.user.name }}</b
                    ><br /><button
                      @click="handleCopy(user.user.name)"
                      class="firefoo__button"
                    >
                      <span v-if="copied == user.user.name">Copied Name!</span
                      ><span v-else>Copy Name</span>
                    </button>
                  </td>
                  <td class="table__cell table__cell--user">
                    <a
                      v-if="displayGumroadLicenseData"
                      :href="`/firefoo/${user.user.id}`"
                      >{{ user.user.id }}</a
                    ><span v-else>{{ user.user.id }}</span>
                    <br />
                    <button
                      @click="handleCopy(user.user.id)"
                      class="firefoo__button"
                    >
                      <span v-if="copied == user.user.id">Copied Figma ID!</span
                      ><span v-else>Copy Figma ID</span>
                    </button>
                  </td>

                  <td class="table__cell table__cell--user">
                    {{ formatDate(user.user.created_at) }}
                  </td>
                  <td class="table__cell table__cell--user">
                    {{ formatDate(user.user.last_seen) }}
                  </td>
                  <!-- <td class="table__cell table__cell--user">{{ user.plugin.key }}</td> -->
                  <!-- <td class="table__cell table__cell--user">
                  {{ formatDate(user.plugin.last_used) }}
                </td> -->
                  <td valign="top" class="table__cell table__cell--user">
                    <ul class="table__list">
                      <li v-for="plugin in user.plugins" :key="plugin.id">
                        <div v-if="plugin.name != 'unknown'">
                          <small>
                            <b>Plugin</b>: {{ plugin.name }} ({{
                              plugin.id
                            }})<br />
                            <b>Last Plugin Run</b>:
                            {{ formatDate(plugin.last_used) }}<br />
                            <b>Trials left</b>: {{ plugin.trialsLeft }}<br />
                            <b>Key</b>:
                            <a
                              v-if="displayFigmaUserData"
                              :href="`/firefoo/${plugin.key}`"
                              >{{ plugin.key }}</a
                            ><span v-else>{{ plugin.key }}</span></small
                          >

                          <div v-if="displayFigmaUserData">
                            <button
                              class="firefoo__button"
                              :disabled="newTrialCount == ''"
                              @click="
                                updateTrials({
                                  pluginId: plugin.id,
                                  userId: user.user.id
                                })
                              "
                            >
                              <span v-if="newTrialCount == ''"
                                >Update&nbsp;Free&nbsp;Trials</span
                              >
                              <span v-else
                                >Update&nbsp;Free&nbsp;Trials to
                                {{ newTrialCount }}</span
                              >
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <!-- <td class="table__cell table__cell--user">{{ user.plugin.trialsLeft }}</td> -->
                  <!-- <td
                    v-if="displayGumroadLicenseData"
                    class="table__cell table__cell--user"
                  >
                    <button
                      class="firefoo__button firefoo__button--destroy"
                      @click="
                        removeUserSeats({
                          userId: user.user.id,
                          licenseKey: license.key
                        })
                      "
                    >
                      <span>Remove User from License</span>
                    </button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          v-if="
            licenses.length && purchases.length && displayGumroadLicenseData
          "
          class="firefoo__content firefoo__content--secondary"
        >
          <div class="firefoo__header">
            <h3 class="firefoo__heading">
              Gumroad Subscription Receipts
            </h3>

            <button
              @click="copyReceiptsList(purchases)"
              class="firefoo__button"
            >
              <span v-if="copied == 'receiptsList'"
                >Copied List of Receipt Links!</span
              ><span v-else>Copy List of Receipt Links</span>
            </button>
          </div>

          <table class="firefoo__table">
            <thead>
              <tr>
                <th>Receipt Link (Newest to Oldest)</th>
                <th>Product Link</th>
                <!-- <th>ID</th> -->
                <!-- <th>Resource Name</th> -->
                <!-- <th>IP Country</th> -->
                <!-- <th>Dispute Won</th> -->
                <!-- <th>Product Name</th> -->
                <th>Seat Count</th>
                <!-- <th>Multi-seat License</th> -->
                <!-- <th>Short Product ID</th> -->
                <!-- <th>Seller ID</th> -->
                <!-- <th>Purchaser ID</th> -->
                <th>Currency</th>
                <!-- <th>Offer Code</th> -->
                <!-- <th>Recurrence</th> -->
                <th>Email</th>
                <!-- <th>Disputed</th> -->
                <!-- <th>Can Contact</th> -->
                <!-- <th>License Key</th> -->
                <!-- <th>Order Number</th> -->
                <!-- <th>Sale ID</th> -->
                <!-- <th>Gift Receiver Purchase</th> -->
                <!-- <th>Gumroad Fee</th> -->
                <th>Card</th>
                <!-- <th>Product ID</th> -->
                <!-- <th>Discover Fee Charged</th> -->
                <!-- <th>Permalink</th> -->
                <th>Price</th>
                <!-- <th>Tier</th> -->
                <!-- <th>Recurring Charge</th> -->
                <!-- <th>Sale Timestamp</th> -->
                <th>Charge Refunded</th>
                <!-- <th>Referrer</th> -->

                <!-- <th>Subscription ID</th> -->
                <!-- <th>Full Name</th> -->
              </tr>
            </thead>
            <tbody v-if="!loadingResults">
              <tr v-for="purchase in purchases" :key="purchase.id">
                <td>
                  <a
                    :href="
                      `https://app.gumroad.com/purchases/${purchase.sale_id}/receipt`
                    "
                    target="_blank"
                    :title="purchase.sale_id"
                    >{{ formatTimestamp(purchase.sale_timestamp) }}</a
                  ><br />

                  <button
                    @click="
                      handleCopy(
                        `https://app.gumroad.com/purchases/${purchase.sale_id}/receipt`
                      )
                    "
                    class="firefoo__button"
                  >
                    <span
                      v-if="
                        copied ==
                          `https://app.gumroad.com/purchases/${purchase.sale_id}/receipt`
                      "
                      >Copied Receipt Link!</span
                    ><span v-else>Copy Receipt Link</span>
                  </button>
                </td>
                <td>
                  <a :href="purchase.product_permalink" target="_blank">{{
                    purchase.product_name
                  }}</a>
                </td>
                <!-- <td>{{ purchase.id }}</td> -->
                <!-- <td>{{ purchase.resource_name }}</td> -->
                <!-- <td>{{ purchase.ip_country }}</td> -->
                <!-- <td>{{ purchase.dispute_won }}</td> -->
                <!-- <td>{{ purchase.product_name }}</td> -->
                <td>{{ purchase.quantity }}</td>
                <!-- <td>{{ purchase.is_multiseat_license }}</td> -->
                <!-- <td>{{ purchase.short_product_id }}</td> -->
                <!-- <td>{{ purchase.seller_id }}</td> -->
                <!-- <td>{{ purchase.purchaser_id }}</td> -->
                <td>{{ purchase.currency.toUpperCase() }}</td>
                <!-- <td>{{ purchase.offer_code }}</td> -->
                <!-- <td>{{ purchase.recurrence }}</td> -->
                <td>{{ purchase.email }}</td>
                <!-- <td>{{ purchase.disputed }}</td> -->
                <!-- <td>{{ purchase.can_contact }}</td> -->
                <!-- <td>{{ purchase.license_key }}</td> -->
                <!-- <td>{{ purchase.order_number }}</td> -->
                <!-- <td>{{ purchase.sale_id }}</td> -->
                <!-- <td>{{ purchase.is_gift_receiver_purchase }}</td> -->
                <!-- <td>{{ purchase.gumroad_fee }}</td> -->
                <td>
                  {{ purchase.card.type }}<br />{{
                    purchase.card.visual.replace("**** **** ****", "****")
                  }}
                </td>
                <!-- <td>{{ purchase.product_id }}</td> -->
                <!-- <td>{{ purchase.discover_fee_charged }}</td> -->
                <!-- <td>{{ purchase.permalink }}</td> -->
                <td>{{ formatCentsToDollars(purchase.price) }}</td>
                <!-- <td>{{ purchase.variants.Tier }}</td> -->
                <!-- <td>{{ purchase.is_recurring_charge }}</td> -->
                <!-- <td>{{ formatTimestamp(purchase.sale_timestamp) }}</td> -->
                <td>
                  <span v-if="purchase.refunded">Yes</span
                  ><span v-else>No</span>
                </td>
                <!-- <td>{{ purchase.referrer }}</td> -->

                <!-- <td>{{ purchase.subscription_id }}</td> -->
                <!-- <td>{{ purchase.full_name }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import Spinner from "@/components/Spinner.vue";
import Header from "@/components/Header.vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import { db } from "../db";

// const db = firebase.firestore();
// const storageRef = firebase.storage().ref();

export default {
  name: "Firefoo",
  components: {
    Login,
    Spinner,
    Header
  },
  mounted() {
    // Example Gumroad Key: 2724E727-BB0C462B-88D973D6-CCAEACF8

    // adam@figmatic.com
    // UID: MGlrxkO5IPdi9SKb2wEivyLGBfU2
    // console.log("preauth");
    firebase.auth().onAuthStateChanged(async user => {
      // console.log("auth");
      // document.title = "Loading...";
      if (user) {
        // if (
        //   user.email !== `crypto_${this.$route.params.userId}@figmaticapp.com`
        // ) {
        //   firebase.auth().signOut();
        // } else {

        // Check if user account is adam@figmatic.com
        if (user.uid !== "MGlrxkO5IPdi9SKb2wEivyLGBfU2") {
          this.error = "404 - Page Not Found";
          this.handleLogout();
        }

        this.user = user;

        const licenseKey = this.$route.params.licenseKey;

        if (licenseKey) {
          this.setSearchTerm(licenseKey);
        }
        // const id = this.$route.params.orgId
        //   ? this.$route.params.orgId
        //   : this.user.uid;
        // const doc = await this.fetchDocument(id);
        // // console.log(doc);
        // this.document = doc;
        // this.permalink = doc.subscriptions[0].permalink;
        this.loading = false;
        // }
      } else {
        document.title = "Hypermatic Enterprise Login";
        this.user = {};
        this.loading = false;
      }
    });
  },
  data() {
    return {
      searchTerm: "",
      permalink: "",
      newEmail: "",
      customNewSeatCount: "",
      showArchived: false,
      loadingResults: false,
      displayFigmaUserData: false,
      displayGumroadLicenseData: false,
      loading: true,
      licenses: [],
      purchases: [],
      users: [],
      document: { isDeleted: false },
      gumroadData: {},
      user: {},
      error: "",
      copied: "",
      newTrialCount: 10,
      docId: this.$route.params.docId
    };
  },
  // firestore: {
  //   licenses: firebase
  //     .firestore()
  //     .collection(`enterprise_users`)
  //     .doc(this.user.uid)
  //     .collection(`licenses`),
  // },
  computed: {
    // activeLicenses() {
    //   return this.licenses.filter(license => license.active);
    // }
    checkedUsersToDelete() {
      if (!this.users.length) {
        return [];
      }

      const checked = this.users.filter(user => user.user.checked === true);

      return checked;
    }
  },
  methods: {
    async getOverusedSeats() {
      try {
        const collectionRef = firebase.firestore().collection("gumroad_keys");
        const snapshot = await collectionRef.get();

        const overusedSeats = [];

        snapshot.forEach(doc => {
          const data = doc.data();
          if (
            Array.isArray(data.users) &&
            data.users.length >= data.seats + 1
          ) {
            overusedSeats.push({
              email: data.email,
              users_count: data.users.length,
              seats: data.seats,
              key: data.key
            });
          }
        });

        // Sort the array by the length of the users array in descending order
        overusedSeats.sort((a, b) => b.users_count - a.users_count);

        return overusedSeats;
      } catch (error) {
        // console.error("Error getting documents: ", error);
        // throw new Error("Failed to get documents");
      }
    },
    convertToCSV(data) {
      const headers = ["email", "users_count", "seats", "key"];
      const csvRows = [];
      csvRows.push(headers.join(",")); // Add header row

      data.forEach(row => {
        const values = headers.map(header => row[header]);
        csvRows.push(values.join(","));
      });

      return csvRows.join("\n");
    },

    downloadCsv(csv, filename) {
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async exportOverusedSeats() {
      const data = await this.getOverusedSeats();
      const csv = this.convertToCSV(data);
      this.downloadCsv(csv, "overused_seats.csv");
    },
    async getGumroadSeatCount(product_permalink, license_key) {
      return fetch("https://api.gumroad.com/v2/licenses/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          product_id: product_permalink,
          license_key: license_key
        })
      })
        .then(response => response.json())
        .then(data => data)
        .catch(error => error);
    },
    async bulkSyncGumroadSeatsWithFirebase() {
      if (
        confirm(
          `Are you sure you want to bulk sync ALL Firebase license seat counts with Gumroad API data seat counts?`
        ) == true
      ) {
        const collectionRef = firebase
          .firestore()
          .collection("gumroad_keys")
          .where("multiple_seats", "==", true)
          .where("tier", "==", "pro");

        const snapshot = await collectionRef.get();

        // const totalRecords = snapshot.size;

        // console.log(
        //   "Total gumroad_keys records to Loop through:",
        //   totalRecords
        // );

        // let index = 0;
        // let updated = 0;
        let keysUpdated = [];

        for (const doc of snapshot.docs) {
          try {
            // index++;
            // const percentage = (index / totalRecords) * 100;
            // console.log(
            //   `Checking ${index}/${totalRecords} (${percentage.toFixed(2)}%)`
            // );

            const data = doc.data();
            const {
              seats,
              tier,
              multiple_seats,
              subscription_id,
              key,
              email
            } = data;

            if (multiple_seats && tier === "pro") {
              const purchaseSnapshot = await firebase
                .firestore()
                .collection("gumroad_purchases")
                .where("subscription_id", "==", subscription_id)
                .get();

              const purchases = purchaseSnapshot.docs
                .map(purchaseDoc => ({
                  id: purchaseDoc.id,
                  ...purchaseDoc.data()
                }))
                .sort((a, b) => {
                  return (
                    new Date(b.sale_timestamp) - new Date(a.sale_timestamp)
                  );
                });

              const latestPurchase = purchases.length > 0 ? purchases[0] : null;

              if (latestPurchase !== null) {
                const gumroadSeatCount =
                  typeof latestPurchase.quantity === "string"
                    ? parseInt(latestPurchase.quantity)
                    : latestPurchase.quantity;

                if (
                  typeof gumroadSeatCount === "number" &&
                  seats !== gumroadSeatCount &&
                  gumroadSeatCount > 0
                ) {
                  // console.log(
                  //   `UPDATING SEATS: Gumroad (${gumroadSeatCount}) and Firebase (${seats}) - ${email} - ${key}`
                  // );

                  await doc.ref.update({
                    seats: gumroadSeatCount
                  });

                  // updated++;
                  keysUpdated.push({ key, email });
                  // console.log(data);
                }
              }
            }
          } catch (e) {
            // console.log("e", e);
          }
        }

        // console.log(`SUCCESS! Updated ${updated}/${totalRecords} records`);
        this.downloadJSON(keysUpdated);
      }
    },
    setSearchTerm(term) {
      this.searchTerm = term;
      this.searchByKeyword();
    },
    downloadJSON(keysUpdated) {
      const dataStr = JSON.stringify(keysUpdated, null, 2); // Convert JSON to string
      const blob = new Blob([dataStr], { type: "application/json" }); // Create a Blob from the JSON string
      const url = URL.createObjectURL(blob); // Create a URL for the Blob

      const a = document.createElement("a"); // Create an anchor element
      a.href = url; // Set the href attribute to the Blob URL
      a.download = "keysUpdated.json"; // Set the download attribute with the desired file name
      document.body.appendChild(a); // Append the anchor element to the document body
      a.click(); // Programmatically click the anchor element to trigger the download
      document.body.removeChild(a); // Remove the anchor element from the document body

      URL.revokeObjectURL(url); // Revoke the Blob URL to free up resources
    },
    async handleSeatCountSync(data) {
      const { key, firebaseSeats, gumroadSeats } = data;

      const gumroadSeatCount =
        typeof gumroadSeats === "string"
          ? parseInt(gumroadSeats)
          : gumroadSeats;

      if (
        confirm(
          `Are you sure you want to update the Firebase seats from '${firebaseSeats}' to '${gumroadSeatCount}' on this license (${key})?`
        ) == true
      ) {
        try {
          const docRef = firebase
            .firestore()
            .collection("gumroad_keys")
            .doc(key);
          const doc = await docRef.get();

          if (doc.exists) {
            await docRef.update({
              seats: gumroadSeatCount
            });

            alert(
              `Yay! Updated the Firebase seats count from '${firebaseSeats}' to '${gumroadSeatCount}'!`
            );
          }
        } catch (e) {
          // console.log(e);
        }
      }
    },
    handleCopy(str) {
      navigator.clipboard
        .writeText(str)
        .then(() => {
          // console.log("List copied to clipboard!");
          this.copied = str;
        })
        .catch(() => {
          this.copied = "";
          // console.error("Failed to copy text: ", err);
        });
    },
    copyUsersList(items) {
      const usersList = items.map(item => `${item.user.name}`).join("\n");

      navigator.clipboard
        .writeText(usersList)
        .then(() => {
          // console.log("List copied to clipboard!");
          this.copied = "usersList";
        })
        .catch(() => {
          this.copied = "";
          // console.error("Failed to copy text: ", err);
        });

      // copy(usersList);
    },
    async copyReceiptsList(items) {
      // Convert the array of strings to an HTML list with anchor tags
      const htmlList = items
        .map(
          item =>
            `<a href="https://app.gumroad.com/purchases/${
              item.sale_id
            }/receipt">${this.formatTimestamp(item.sale_timestamp).substring(
              this.formatTimestamp(item.sale_timestamp).indexOf(" ") + 1
            )}</a>`
        )
        .join("<br>");

      // Copy the HTML list to the clipboard
      try {
        await navigator.clipboard.write([
          new window.ClipboardItem({
            "text/html": new Blob([htmlList], { type: "text/html" })
          })
        ]);
        this.copied = "receiptsList";
      } catch (err) {
        // console.error("Failed to copy text: ", err);
      }

      // copy(usersList);
    },
    validKeyPattern(key) {
      const re = new RegExp(
        "^[A-Z0-9]{8}-[A-Z0-9]{8}-[A-Z0-9]{8}-[A-Z0-9]{8}$"
      );
      return re.test(key);
    },
    isFigmaId(str) {
      return /^\d+$/.test(str);
    },
    formatCard(card) {
      return `${card.type} ${card.visual}`;
    },
    // restoreUserSeat(license) {
    //   const { userId, licenseKey } = license;
    //   console.log("userId", userId);
    //   console.log("licenseKey", licenseKey);
    // },
    async removeUserSeats(licenseKey) {
      // const { userId, licenseKey } = license;

      // TODO - Refactor into a list of checkboxes

      const usersToRemove = this.checkedUsersToDelete;

      let userIdsToRemove = usersToRemove.map(u => u.user.id);

      // console.log("licenseKey", licenseKey);
      if (
        confirm(
          `Are you sure you want to permanently remove ${usersToRemove.length} seat from this license?`
        ) == true
      ) {
        try {
          const docRef = firebase
            .firestore()
            .collection("gumroad_keys")
            .doc(licenseKey);

          const doc = await docRef.get();
          if (doc.exists) {
            const data = doc.data();

            if (data.users) {
              const updatedUsers = data.users.filter(
                id => !userIdsToRemove.includes(`${id}`)
              );

              await docRef.update({
                users: updatedUsers
              });
            }

            for (const user of usersToRemove) {
              const userId = user.user.id;

              if (data.users && data.users.includes(userId)) {
                // Remove the user ID from the users array

                // Now update the user's document in figma_users collection
                const userDocRef = firebase
                  .firestore()
                  .collection("figma_users")
                  .doc(userId);

                const userDoc = await userDocRef.get();

                if (userDoc.exists) {
                  const userData = userDoc.data();

                  const updatedPlugins = { ...userData.plugins };

                  Object.keys(updatedPlugins).forEach(pluginId => {
                    if (updatedPlugins[pluginId].key === licenseKey) {
                      updatedPlugins[pluginId].key = "";
                    }
                  });

                  // console.log("set updatedPlugins", updatedPlugins);

                  await userDocRef.update({
                    plugins: updatedPlugins
                  });
                } else {
                  // console.log(
                  //   `User document with ID ${userId} not found in figma_users collection.`
                  // );
                }
              } else {
                // console.log(`User ${userId} not found in the users array.`);
              }
            }
          } else {
            // console.log(`Document with license key ${licenseKey} not found.`);
          }

          alert(
            `Yay! Removed the ${usersToRemove.length} Figma users from the license (${licenseKey})!`
          );
        } catch (error) {
          // console.error("Error archiving user and updating plugins: ", error);
        }
      }
    },
    async updateTrials(userDetail) {
      const { pluginId, userId } = userDetail;

      this.trialsUpdated = "";

      let newTrialCount = parseInt(this.newTrialCount);

      // console.log("licenseKey", licenseKey);
      if (
        confirm(
          `Are you sure you want update ${this.getPluginName(
            pluginId
          )} to have ${newTrialCount} free Pro trials on this user's Figma account?`
        ) == true
      ) {
        try {
          // Now update the user's document in figma_users collection
          const userDocRef = firebase
            .firestore()
            .collection("figma_users")
            .doc(userId);

          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();

            const updatedPluginTrials = { ...userData.plugins };

            updatedPluginTrials[pluginId].trialsLeft = newTrialCount;

            await userDocRef.update({
              plugins: updatedPluginTrials
            });

            alert(
              `Yay! Updated ${this.getPluginName(
                pluginId
              )} trials to ${newTrialCount} for this Figma user!`
            );
          } else {
            // console.log(
            //   `User document with ID ${userId} not found in figma_users collection.`
            // );
          }
        } catch (e) {
          // console.log(
          //   `User document with ID ${userId} not found in figma_users collection.`
          // );
        }
      }
    },
    formatCentsToDollars(cents) {
      return `$${(cents / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      // Function to get the ordinal suffix for a number
      const getOrdinalSuffix = day => {
        if (day > 3 && day < 21) return "th"; // Handle special case for 11th, 12th, 13th, etc.
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      // Get the day, month, and year
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Construct the final date string with the ordinal suffix
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    },
    formatDate(timestamp) {
      if (!timestamp) return "";
      const date = new Date(timestamp.seconds * 1000);
      // Function to get the ordinal suffix for a number

      // Function to get the ordinal suffix for a number
      const getOrdinalSuffix = day => {
        if (day > 3 && day < 21) return "th"; // Handle special case for 11th, 12th, 13th, etc.
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      // Use Intl.DateTimeFormat for localization
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "Australia/Melbourne"
      };

      const formatter = new Intl.DateTimeFormat("en-AU", options);

      // Get the formatted parts
      const parts = formatter.formatToParts(date);
      let day, month, year;

      for (const part of parts) {
        if (part.type === "day") {
          day = part.value;
        } else if (part.type === "month") {
          month = part.value;
        } else if (part.type === "year") {
          year = part.value;
        }
      }

      // Construct the final date string with the ordinal suffix
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    },

    async getReceiptsById(subscription_id) {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("gumroad_purchases")
          .where("subscription_id", "==", subscription_id)
          .get();
        this.purchases = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .sort((a, b) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.sale_timestamp) - new Date(a.sale_timestamp);
          });
      } catch (error) {
        // console.error("Error searching documents: ", error);
      }
    },
    async fetchLicenses() {
      // console.log("this.permalinkId", this.permalinkId);
      // console.log("this.orgId", this.orgId);
      // if (this.validKeyPattern(this.searchTerm)) {
      //   this.$bind(
      //     "licenses",
      //     firebase
      //       .firestore()
      //       .collection("gumroad_keys")
      //       .doc(this.searchTerm)
      //   );
      // }
    },
    async searchByKeyword() {
      if (!this.searchTerm) return;

      this.users = [];
      this.purchases = [];
      this.licenses = [];

      let isFigmaUser = this.isFigmaId(this.searchTerm);
      let isGumroadLicense = this.validKeyPattern(this.searchTerm);

      this.displayFigmaUserData = isFigmaUser;
      this.displayGumroadLicenseData = isGumroadLicense;

      this.loadingResults = true;

      try {
        if (isFigmaUser) {
          const userDoc = await firebase
            .firestore()
            .collection("figma_users")
            .doc(this.searchTerm)
            .get();

          if (userDoc.exists) {
            const userData = { id: userDoc.id, ...userDoc.data() };

            const plugins = Object.entries(userData.plugins).map(([id, p]) => ({
              ...p,
              id,
              name: this.getPluginName(id)
            }));

            const user = {
              ...userData,
              plugins: plugins.sort((a, b) => a.name.localeCompare(b.name))
            };

            this.users = [user];
            this.licenses = [user];
            this.loadingResults = false;
          } else {
            this.users = [];
            this.loadingResults = false;
          }
        } else if (isGumroadLicense) {
          const docRef = firebase
            .firestore()
            .collection("gumroad_keys")
            .doc(this.searchTerm);
          const doc = await docRef.get();
          if (doc.exists) {
            const data = { id: doc.id, ...doc.data() };

            // console.log("data", data);

            this.licenses = [data];

            if (
              this.licenses[0].test === false &&
              this.licenses[0].subscription_id !== undefined
            ) {
              await this.getReceiptsById(this.licenses[0].subscription_id);
            }

            const gumroadLicense = await this.checkGumroadSubscription(
              this.licenses[0].permalink,
              this.licenses[0].key
            );

            // console.log("gumroadLicense", gumroadLicense);

            this.gumroadData = gumroadLicense;

            if (data.users && data.users.length > 0) {
              const userPromises = data.users.map(userId =>
                firebase
                  .firestore()
                  .collection("figma_users")
                  .doc(userId)
                  .get()
              );
              const userDocs = await Promise.all(userPromises);
              const users = userDocs
                .map(userDoc => {
                  if (!userDoc.exists) return null;
                  const userData = { id: userDoc.id, ...userDoc.data() };

                  const plugins = Object.entries(userData.plugins)
                    .filter(([id, p]) => p.key === data.key && id === id)
                    .map(([id, p]) => ({
                      ...p,
                      id,
                      name: this.getPluginName(id)
                    }));
                  return {
                    ...userData,
                    plugins: plugins.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )
                  };
                })
                .filter(user => user)
                .sort((a, b) => {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.created_at) - new Date(a.created_at);
                });

              this.users = users;

              this.loadingResults = false;
            }

            // if (data.archived_users && data.archived_users.length > 0) {
            //   const archivedUserPromises = data.archived_users.map(userId =>
            //     firebase
            //       .firestore()
            //       .collection("figma_users")
            //       .doc(userId)
            //       .get()
            //   );
            //   const archivedUserDocs = await Promise.all(archivedUserPromises);
            //   const archivedUsers = archivedUserDocs
            //     .map(archivedUserDoc => {
            //       if (!archivedUserDoc.exists) return null;
            //       const archivedUserData = {
            //         id: archivedUserDoc.id,
            //         ...archivedUserDoc.data()
            //       };

            //       console.log("archivedUserData init", archivedUserData);

            //       const plugins = Object.entries(archivedUserData.plugins)
            //         .filter(([id, p]) => p.key === data.key && id === id)
            //         .map(([id, p]) => ({ ...p, id }));
            //       return { ...archivedUserData, plugins };
            //     })
            //     .filter(user => user)
            //     .sort((a, b) => {
            //       // Turn your strings into dates, and then subtract them
            //       // to get a value that is either negative, positive, or zero.
            //       return new Date(b.created_at) - new Date(a.created_at);
            //     });

            //   this.licenses[0].archived_users = archivedUsers;
            // } else {
            //   const archivedUsers = data.archived_users || [];
            //   this.licenses[0].archived_users = archivedUsers;
            // }
          } else {
            this.users = [];
            this.licenses = [];
            this.loadingResults = false;
          }
        }
      } catch (error) {
        // console.error("Error searching documents: ", error);
        this.loadingResults = false;
      }
      this.loadingResults = false;
    },
    // async fetchDocument() {
    //   // console.log("userId", userId);
    //   const orgId = this.$route.params.orgId;

    //   let snapshot = await firebase
    //     .firestore()
    //     .collection("enterprise_users")
    //     .doc(orgId)
    //     .get()
    //     .then(doc => {
    //       return { id: doc.id, ...doc.data() };
    //     });

    //   this.$bind(
    //     "licenses",
    //     firebase
    //       .firestore()
    //       .collection("enterprise_keys")
    //       .where("org", "==", orgId)
    //   );

    //   return snapshot;
    // },
    async handleLogin(authenticated) {
      // console.log(authenticated.user);
      this.user = authenticated.user;
      // const id = this.$route.params.orgId
      //   ? this.$route.params.orgId
      //   : this.user.uid;
      // const doc = await this.fetchDocument();
      // // console.log(doc);
      // this.document = doc;
    },
    handleLogout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          this.user = {};
        })
        .catch(() => {
          // console.log(error);
          // An error happened.
        });
    },
    handleError(error) {
      this.user = {};
      switch (error.code) {
        case "auth/wrong-password":
          this.error = `Oops! That password isn't correct.`;
          break;
        case "auth/user-not-found":
          this.error = `Oops! This user wasn't found.`;
          break;
        default:
          this.error = error.message;
      }
    },
    getPluginName(pluginId) {
      const pluginsList = [
        {
          figma_id: "796124491692147799",
          name: "Bannerify"
        },
        {
          figma_id: "1030968261424288884",
          name: "Commentful"
        },
        {
          figma_id: "849159306117999028",
          name: "Convertify"
        },
        {
          figma_id: "900893606648879767",
          name: "CopyDoc"
        },
        {
          figma_id: "819688895973707151",
          name: "Crypto"
        },
        {
          figma_id: "910671699871076601",
          name: "Emailify"
        },
        {
          figma_id: "789010114208495133",
          name: "Favvy"
        },
        {
          figma_id: "818960915730257192",
          name: "HyperCrop"
        },
        {
          figma_id: "838925615018625519",
          name: "Pitchdeck"
        },
        {
          figma_id: "816424186244407164",
          name: "Pixelay"
        },
        {
          figma_id: "789009980664807964",
          name: "TinyImage"
        }
      ];

      const matched = pluginsList.find(p => p.figma_id === pluginId);

      if (matched !== undefined) {
        return matched.name;
      }

      return "unknown";
    },
    async checkGumroadSubscription(product_permalink, license_key) {
      const product_ids = {
        piyjt: "wye5lxKiEzKmcwXE66_jcA==", // Weblify (Multi-Seat)
        weblify: "wye5lxKiEzKmcwXE66_jcA==", // Weblify (Multi-Seat)
        lzhhcl: "YtBbUQl8aXK9NFFkbSxeZg==", // Commentful (Multi-Seat)
        commentful: "YtBbUQl8aXK9NFFkbSxeZg==", // Commentful (Multi-Seat)
        arvdj: "O9582Lkkf7NqxRcIb1dAJA==", // Pro Bundle (Multi-Seat)
        bundle: "O9582Lkkf7NqxRcIb1dAJA==", // Pro Bundle (Multi-Seat)
        updod: "iAlfHJ3atH9W0TAEFhVX6Q==", // Emailify (Multi-Seat)
        emailify: "iAlfHJ3atH9W0TAEFhVX6Q==", // Emailify (Multi-Seat)
        lrfuis: "zgTVfEeUFRum29fgvnI_wQ==", // CopyDoc (Multi-Seat)
        copydoc: "zgTVfEeUFRum29fgvnI_wQ==", // CopyDoc (Multi-Seat)
        oahyv: "XmnY9aAjX3cDUHgY3FIlUQ==", // HyperCrop (Multi-Seat)
        hypercrop: "XmnY9aAjX3cDUHgY3FIlUQ==", // HyperCrop (Multi-Seat)
        fzmjf: "QrtbWg52IFuGrn5jwtx-MA==", // Convertify (Multi-Seat)
        convertify: "QrtbWg52IFuGrn5jwtx-MA==", // Convertify (Multi-Seat)
        hkkml: "DxMCneBCPFbhel0Hs4G0OA==", // Favvy (Multi-Seat)
        favvy: "DxMCneBCPFbhel0Hs4G0OA==", // Favvy (Multi-Seat)
        saldw: "y3ki9bR27Jeam5ddL6iu2A==", // Pixelay (Multi-Seat)
        pixelay: "y3ki9bR27Jeam5ddL6iu2A==", // Pixelay (Multi-Seat)
        iccid: "p1lXKxPvwi6IqreUZsglxQ==", // Crypto (Multi-Seat)
        crypto: "p1lXKxPvwi6IqreUZsglxQ==", // Crypto (Multi-Seat)
        ffigd: "ik7qGD-Tn9NnL1ZgjjH-6w==", // Pitchdeck (Multi-Seat)
        pitchdeck: "ik7qGD-Tn9NnL1ZgjjH-6w==", // Pitchdeck (Multi-Seat)
        fqhgoy: "S5wlkMBNCIQSxXXthNUEHw==", // Bannerify (Multi-Seat)
        bannerify: "S5wlkMBNCIQSxXXthNUEHw==", // Bannerify (Multi-Seat)
        svzjog: "cnB5KlRGUhQTI-5rE20duQ==", // TinyImage (Multi-Seat)
        tinyimage: "cnB5KlRGUhQTI-5rE20duQ==", // TinyImage (Multi-Seat)
        vwuyc: "IV6hmfqrh1tbQfn2kZjU5w==", // Commentful (Business)
        commentfulbusiness: "IV6hmfqrh1tbQfn2kZjU5w==", // Commentful (Business)
        npbov: "fCyyurHs3CUYtz_wW-GDBg==", // Commentful (Team)
        commentfulteam: "fCyyurHs3CUYtz_wW-GDBg==", // Commentful (Team)
        wpohs: "LU7wE9IqOQgA23R-HJCwcg==", // Commentful (Freelancer)
        commentfulfreelancer: "LU7wE9IqOQgA23R-HJCwcg==", // Commentful (Freelancer)
        JkmZx: "rLzyVHaKnWISSGVkpqcsRg==", // Pro Bundle (Business)
        bundlebusiness: "rLzyVHaKnWISSGVkpqcsRg==", // Pro Bundle (Business)
        DYhREl: "7y2nVLX2XqPtENOp_miqIQ==", // Pro Bundle (Team)
        bundleteam: "7y2nVLX2XqPtENOp_miqIQ==", // Pro Bundle (Team)
        CubOx: "jhkbIEUHDs6qNFXE0VPokw==", // Pro Bundle (Freelancer)
        bundlefreelancer: "jhkbIEUHDs6qNFXE0VPokw==", // Pro Bundle (Freelancer)
        mndpGd: "N5CckidHuHPdxJPALwW6zg==", // Emailify (Business)
        emailifybusiness: "N5CckidHuHPdxJPALwW6zg==", // Emailify (Business)
        qSszQ: "aUWVrYHiGVuxFIHqrz1YIA==", // Emailify (Team)
        emailifyteam: "aUWVrYHiGVuxFIHqrz1YIA==", // Emailify (Team)
        iGPbA: "iRAX5zNvEZy-eiVU1t7MIg==", // Emailify (Freelancer)
        emailifyfreelancer: "iRAX5zNvEZy-eiVU1t7MIg==", // Emailify (Freelancer)
        nbMdt: "ySFY2r7p9Z7pk_YfeAmoqA==", // CopyDoc (Business)
        copydocbusiness: "ySFY2r7p9Z7pk_YfeAmoqA==", // CopyDoc (Business)
        evCtv: "XMZ8sv_BfvW0WGHmAW_sHw==", // CopyDoc (Team)
        copydocteam: "XMZ8sv_BfvW0WGHmAW_sHw==", // CopyDoc (Team)
        mcsLQ: "qnEP2UWQACTNxVHuxNwIUw==", // CopyDoc (Freelancer)
        copydocfreelancer: "qnEP2UWQACTNxVHuxNwIUw==", // CopyDoc (Freelancer)
        KCpag: "Q3q6tGw8eh84jpYjYzV4jA==", // HyperCrop (Business)
        hypercropbusiness: "Q3q6tGw8eh84jpYjYzV4jA==", // HyperCrop (Business)
        QHAZG: "fMTIfgiU2gtJN7nUbfv2EA==", // HyperCrop (Team)
        hypercropteam: "fMTIfgiU2gtJN7nUbfv2EA==", // HyperCrop (Team)
        FkADG: "3OOqmHR8W-BNZmGnaA5tWw==", // HyperCrop (Freelancer)
        hypercropfreelancer: "3OOqmHR8W-BNZmGnaA5tWw==", // HyperCrop (Freelancer)
        drJbd: "-6_D2pjfmOV82vgVH7mSTg==", // Convertify (Business)
        convertifybusiness: "-6_D2pjfmOV82vgVH7mSTg==", // Convertify (Business)
        AwRPF: "MkkT3bOEmPKwN_Vvly02-w==", // Convertify (Team)
        convertifyteam: "MkkT3bOEmPKwN_Vvly02-w==", // Convertify (Team)
        PNDLw: "CAF6R9JsY44fJehL8m9usA==", // Convertify (Freelancer)
        convertifyfreelancer: "CAF6R9JsY44fJehL8m9usA==", // Convertify (Freelancer)
        tdwiM: "yUzxWz6TEzeFNc9cGUUTGA==", // Favvy (Business)
        favvybusiness: "yUzxWz6TEzeFNc9cGUUTGA==", // Favvy (Business)
        rKeoj: "TC1H0K1-_4Yiv4izziasxw==", // Favvy (Team)
        favvyteam: "TC1H0K1-_4Yiv4izziasxw==", // Favvy (Team)
        hRXRQS: "86kKv7N4ZQSwFKtXbHYTww==", // Favvy (Freelancer)
        favvyfreelancer: "86kKv7N4ZQSwFKtXbHYTww==", // Favvy (Freelancer)
        urfGN: "uf26HqASPHZpT4fzQKW4Tw==", // Pixelay (Business)
        pixelaybusiness: "uf26HqASPHZpT4fzQKW4Tw==", // Pixelay (Business)
        psYqj: "KdOcLPiN06uF0WWRjj4QcQ==", // Pixelay (Team)
        pixelayteam: "KdOcLPiN06uF0WWRjj4QcQ==", // Pixelay (Team)
        znros: "SyF56ckoIJx8EGtgCgZl0g==", // Pixelay (Freelancer)
        pixelayfreelancer: "SyF56ckoIJx8EGtgCgZl0g==", // Pixelay (Freelancer)
        NOqos: "UyhqH8dCztqoBMK3YH-tzA==", // Crypto (Business)
        cryptobusiness: "UyhqH8dCztqoBMK3YH-tzA==", // Crypto (Business)
        ogutv: "zNMyYt8WzgEt75Wc9W9EVg==", // Crypto (Team)
        cryptoteam: "zNMyYt8WzgEt75Wc9W9EVg==", // Crypto (Team)
        IBMUI: "FDAcycjzfNGmZNAM8wlvQQ==", // Crypto (Freelancer)
        cryptofreelancer: "FDAcycjzfNGmZNAM8wlvQQ==", // Crypto (Freelancer)
        VenFI: "RuiCOH2J63cPXrMZDNI76Q==", // Pitchdeck (Business)
        pitchdeckbusiness: "RuiCOH2J63cPXrMZDNI76Q==", // Pitchdeck (Business)
        FcPCy: "6Yl80TLXgRow-pMIxeK5bA==", // Pitchdeck (Team)
        pitchdeckteam: "6Yl80TLXgRow-pMIxeK5bA==", // Pitchdeck (Team)
        SUQkWM: "B3LsRzzi_nrApzzJaiEeHQ==", // Pitchdeck (Freelancer)
        pitchdeckfreelancer: "B3LsRzzi_nrApzzJaiEeHQ==", // Pitchdeck (Freelancer)
        wQmLM: "nnGHFuOd73r35gXPrNIcbg==", // Bannerify (Business)
        bannerifybusiness: "nnGHFuOd73r35gXPrNIcbg==", // Bannerify (Business)
        iGAMx: "AlhAsB6MELNA0GNCJncs8Q==", // Bannerify (Team)
        bannerifyteam: "AlhAsB6MELNA0GNCJncs8Q==", // Bannerify (Team)
        TurPb: "EHBOKmLP_Q-FHzG1f0Wu3g==", // Bannerify (Freelancer)
        bannerifyfreelancer: "EHBOKmLP_Q-FHzG1f0Wu3g==", // Bannerify (Freelancer)
        WNknR: "Mqw-SuwUbjsLyUl0cL7NwQ==", // TinyImage (Business)
        tinyimagebusiness: "Mqw-SuwUbjsLyUl0cL7NwQ==", // TinyImage (Business)
        BdjqZ: "UbbebKfcyn1tbGmoFfWRsg==", // TinyImage (Team)
        tinyimageteam: "UbbebKfcyn1tbGmoFfWRsg==", // TinyImage (Team)
        wOXfj: "87Lz5x2EGS_V2JlT5Wdj4A==", // TinyImage (Freelancer)
        tinyimagefreelancer: "87Lz5x2EGS_V2JlT5Wdj4A==", // TinyImage (Freelancer)
        arPVe: "uEev7TsKllwd_EnLqx9NAw==", // TinyImage (AU Freelancer)
        autinyimagefreelancer: "uEev7TsKllwd_EnLqx9NAw==", // TinyImage (AU Freelancer)
        ohhKQz: "Ra2QwasjuglEpb-WkvjCKA==", // Crypto (AU Freelancer)
        aucryptofreelancer: "Ra2QwasjuglEpb-WkvjCKA==", // Crypto (AU Freelancer)
        qHFQa: "4qpVFXGmGA-oPLCIqg0tnQ==", // Pixelay (AU Freelancer)
        aupixelayfreelancer: "4qpVFXGmGA-oPLCIqg0tnQ==", // Pixelay (AU Freelancer)
        FXIjH: "sPdQCFeEeXE-CZebgY5zsg==", // Pitchdeck (AU Freelancer)
        aupitchdeckfreelancer: "sPdQCFeEeXE-CZebgY5zsg==", // Pitchdeck (AU Freelancer)
        ArrjN: "h5_dVrS_8b3EtjnGZUIy8g==", // Pitchdeck (AU Business)
        aupitchdeckbusiness: "h5_dVrS_8b3EtjnGZUIy8g==", // Pitchdeck (AU Business)
        irOBt: "-o9ToTeFd8upn49Pot85ew==", // Pitchdeck (AU Team)
        aupitchdeckteam: "-o9ToTeFd8upn49Pot85ew==", // Pitchdeck (AU Team)
        DFUob: "3oGeLgg7H6jtXn1Tjy9UKw==", // Pitchdeck (Legacy Freelancer)
        pitchdeckfreelance: "3oGeLgg7H6jtXn1Tjy9UKw==", // Pitchdeck (Legacy Freelancer)
        VpYza: "MurNgAYSEj1ZgNLhnF0ayQ==", // Crypto (AU Business)
        aucryptobusiness: "MurNgAYSEj1ZgNLhnF0ayQ==", // Crypto (AU Business)
        JLnmd: "Al93BdKBgXzmOQUVsClWSw==", // Crypto (AU Team)
        aucryptoteam: "Al93BdKBgXzmOQUVsClWSw==", // Crypto (AU Team)
        OZnNR: "44A5RTrwjkl2k0bpi3qhqw==", // Crypto (Legacy Freelancer)
        cryptofreelance: "44A5RTrwjkl2k0bpi3qhqw==", // Crypto (Legacy Freelancer)
        CXjRe: "v_MoNQ8B0YSiFJHB9p2nAw==", // Pixelay (AU Business)
        aupixelaybusiness: "v_MoNQ8B0YSiFJHB9p2nAw==", // Pixelay (AU Business)
        niefv: "Yiq8Lc2jmIgeygIlX-o7xA==", // Pixelay (AU Team)
        aupixelayteam: "Yiq8Lc2jmIgeygIlX-o7xA==", // Pixelay (AU Team)
        XcmDn: "wqvvcsfOArje9Vp7XM-jsw==", // Pixelay (Legacy Freelancer)
        pixelayfreelance: "wqvvcsfOArje9Vp7XM-jsw==", // Pixelay (Legacy Freelancer)
        wimcX: "FUo7kr7eDs6AlEF8mDtGXA==", // Bannerify (AU Business)
        aubannerifybusiness: "FUo7kr7eDs6AlEF8mDtGXA==", // Bannerify (AU Business)
        AOPVZ: "78TNqrkJ_ya8sTeJE-kxYg==", // Bannerify (AU Team)
        aubannerifyteam: "78TNqrkJ_ya8sTeJE-kxYg==", // Bannerify (AU Team)
        zWeJn: "r0PpVS0vC9ZTHDKglgKCDQ==", // Bannerify (AU Freelancer)
        aubannerifyfreelancer: "r0PpVS0vC9ZTHDKglgKCDQ==", // Bannerify (AU Freelancer)
        GktBQ: "sSgI5XzgpJHfYsOSlmSZEA==", // TinyImage (AU Business)
        autinyimagebusiness: "sSgI5XzgpJHfYsOSlmSZEA==", // TinyImage (AU Business)
        JYiEs: "NvkHbHOv41SXyy6xFtHC4Q==", // TinyImage (AU Team)
        autinyimageteam: "NvkHbHOv41SXyy6xFtHC4Q==", // TinyImage (AU Team)
        awMdS: "2zpDAINrZvWfYWN1PgGUmQ==", // TinyImage (Legacy Freelancer)
        tinyimagefreelance: "2zpDAINrZvWfYWN1PgGUmQ==" // TinyImage (Legacy Freelancer)
      };

      // const original_permalink = product_permalink;

      if (product_ids[product_permalink] !== undefined) {
        product_permalink = product_ids[product_permalink];
      }

      return fetch("https://api.gumroad.com/v2/licenses/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          product_id: product_permalink,
          license_key: license_key
        })
      })
        .then(response => response.json())
        .then(data => data)
        .catch(error => error);
    }
  }
  // methods: {
  //   async fetchDocument(userId) {
  //     let snapshot = await db
  //       .collection("enterprise_users")
  //       .doc(userId)
  //       .get()
  //       .then((doc) => {
  //         return { id: doc.id, ...doc.data() };
  //       });
  //     return snapshot;
  //   },
  // },
};
</script>

<style lang="scss">
html {
  // background: linear-gradient(45deg, #e978a0, #51b4db);
  // background-repeat: no-repeat;
  // background-position: fixed;
  min-height: 100%;
  background-color: #fcfcfc;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='28'%3E%3Cpath fill='%23ccc' fill-opacity='.1' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 10-4 0h-4.09A25.98 25.98 0 000 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 00-2-2v-2a4 4 0 013.98 3.6 28.09 28.09 0 012.8-3.86A8 8 0 000 6V4a9.99 9.99 0 018.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 000 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0014.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 014 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 00-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0148.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0156 4v2a8 8 0 00-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0156 10v2a2 2 0 00-2 2.07 28.4 28.4 0 012-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 012.8-3.86 8 8 0 00-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 017.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 00-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0136.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 00-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0125.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 00-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0116.34 0z'/%3E%3C/svg%3E");
  background-size: 80px;
}

body {
  background: none;
}

.home {
  padding-top: 82px;
}

@keyframes slideUp {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.firefoo__header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.firefoo__wrapper {
  margin: auto;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 24px;
}

.firefoo__heading {
  margin: 0;
}

.firefoo__heading--search {
  margin-bottom: 8px;
  color: #777;
}

.firefoo__content {
  animation: slideUp 600ms 300ms ease both;
  max-width: 500px;
  padding: 24px;
  margin: 44px auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.firefoo__content--secondary {
  animation-delay: 370ms;
  max-width: none;
  width: 100%;
}

.firefoo__content--search {
  max-width: 660px;
}

.login__input--search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z'/%3E%3C/svg%3E") !important;
}

.login__input--trials {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M22 22h-20c-1.104 0-2-.896-2-2v-12c0-1.104.896-2 2-2h1.626l.078.283c.194.631.518 1.221.95 1.717h-2.154c-.276 0-.5.224-.5.5v5.5h20v-5.5c0-.276-.224-.5-.5-.5h-2.154c.497-.569.853-1.264 1.029-2h1.625c1.104 0 2 .896 2 2v12c0 1.104-.896 2-2 2zm-20-5v2.5c0 .276.224.5.5.5h19c.276 0 .5-.224.5-.5v-2.5h-20zm8.911-5h-2.911c.584-1.357 1.295-2.832 2-4-.647-.001-1.572.007-2 0-2.101-.035-2.987-1.806-3-3-.016-1.534 1.205-3.007 3-3 1.499.006 2.814.872 4 2.313 1.186-1.441 2.501-2.307 4-2.313 1.796-.007 3.016 1.466 3 3-.013 1.194-.899 2.965-3 3-.428.007-1.353-.001-2 0 .739 1.198 1.491 2.772 2 4h-2.911c-.241-1.238-.7-2.652-1.089-3.384-.388.732-.902 2.393-1.089 3.384zm-2.553-7.998c-1.131 0-1.507 1.918.12 1.998.237.012 2.235 0 2.235 0-1.037-1.44-1.52-1.998-2.355-1.998zm7.271 0c1.131 0 1.507 1.918-.12 1.998-.237.012-2.222 0-2.222 0 1.037-1.44 1.507-1.998 2.342-1.998z'/%3E%3C/svg%3E") !important;
  width: 220px;
  margin-top: 16px;
  margin-bottom: 4px;
}

.firefoo__table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
}

thead {
  background: #f9f9f9;
}

tbody tr:hover {
  background-color: #eee;
}

tr:nth-child(even) {
  // background-color: rgba(#fff, 0.2);
}

th,
td {
  text-align: left;
  padding: 4px 16px;
  // color: #fff;
}

th {
  color: #666;
  font-size: 14px;
}

td {
  border-top: 1px solid #eee;
}

.table__cell--inactive {
  color: #999;
  text-decoration: line-through;
}

.table__cell--user {
  vertical-align: top !important;
}

.table__list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 16px;
    padding-bottom: 0;
    border: 1px solid #ccc;

    &:hover {
      background: #fff;
    }

    &:last-child {
      margin-bottom: 0;
      border: 0;
      padding: 0;
    }
  }
}

.licenses__key,
.table__cell--key {
  width: 380px;
}

.licenses__toggle,
.table__cell--toggle {
  width: 90px;
}

.licenses__copy,
.table__cell--copy {
  width: 120px;
}

.firefoo__copy {
  appearance: none;
  outline: 0;
  border: 0;
  text-decoration: underline;
  font-weight: bold;
  padding: 0;
  background: none;
  cursor: pointer;
}

.firefoo__button {
  appearance: none;
  // width: 100%;
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #2f3d4c;
  box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  transition: all 300ms ease;
  display: block;
  text-align: center;
  padding: 8px 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 8px;

  &:focus,
  &:hover {
    background-color: lighten(#2f3d4c, 5%);
    box-shadow: 0 4px 6px rgba(#2f3d4c, 0.25);
  }

  &:active {
    background-color: #2f3d4c;
    box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover {
      background-color: #2f3d4c;
      opacity: 0.3;
      cursor: not-allowed;
      box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
    }
  }
}

.firefoo__button--destroy {
  background-color: white;
  color: red;
  border: 1px solid red;

  &:focus,
  &:hover {
    background-color: red;
    color: white;
  }

  &:active {
    background-color: red;
    color: white;
  }
}
</style>
