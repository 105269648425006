<template>
  <div class="tiles">
    <h2 class="tiles__heading">Subscriptions</h2>
    <div
      class="tiles__tile home__content home__content--primary"
      v-for="subscription in document.subscriptions"
      :key="subscription.id"
    >
      <div class="tiles__header">
        <div class="tiles__header">
          <Kitsune class="tiles__logo" />
          <div>
            <h3 class="keygen__heading">
              <!--  -->
              {{ subscription.name }} (Enterprise)
            </h3>

            <p class="tiles__description">{{ subscription.seats }} Seats</p>
          </div>
        </div>

        <router-link
          :to="`/enterprise/${document.slug}/${subscription.permalink}`"
        >
          Manage Licences
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Kitsune from "@/assets/kitsune.svg";

export default {
  name: "Tiles",
  components: {
    Kitsune,
  },
  props: {
    document: Object,
  },
};
</script>

<style scoped lang="scss">
.tiles {
  max-width: 500px;
  margin: 44px auto;
}

.tiles__heading {
  margin: 0;
  animation: slideUp 600ms 220ms ease both;
}

.keygen__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keygen__heading {
  margin: 0;
}

.tiles__logo {
  width: 44px;
  height: 44px;
  margin-right: 16px;
}

.keygen__description {
  font-size: 14px;
  text-align: right;
  margin: 0;
}

.tiles__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tiles__description {
  font-size: 14px;
  text-align: left;
  margin: 0;
}
</style>
