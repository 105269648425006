var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"licenses"},[_vm._m(0),_c('div',{staticClass:"licenses__search"},[_c('div',{staticClass:"licenses__form"},[_c('label',{staticClass:"login__label"},[_vm._v("Search for email or license key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"login__input login__input--search",attrs:{"placeholder":_vm.searchPlaceholder},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}})]),_c('div',[_c('div',{staticClass:"licenses__header"},[_c('label',{staticClass:"switch"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.onlyShowActive},on:{"input":function($event){_vm.onlyShowActive = !_vm.onlyShowActive}}}),_c('div')]),_c('label',{staticClass:"login__label login__label--toggle"},[_vm._v("Only show active licenses")])])])]),_c('table',{staticClass:"licenses__table"},[_vm._m(1),(_vm.filteredLicenses.length)?_c('tbody',_vm._l((_vm.filteredLicenses),function(license){return _c('tr',{key:license.id},[_c('td',{staticClass:"toggle table__cell table__cell--toggle"},[_c('label',{class:[
              license.active
                ? 'switch switch--active'
                : 'switch switch--inactive',
              !license.active && _vm.seatLimitReached
                ? 'switch switch--disabled'
                : 'switch switch--enabled'
            ]},[_c('input',{attrs:{"type":"checkbox","disabled":!license.active && _vm.seatLimitReached},domProps:{"checked":license.active},on:{"input":function($event){return _vm.handleActiveToggle(license)}}}),_c('div')])]),_c('td',{class:!license.active
              ? 'table__cell table__cell--inactive'
              : 'table__cell',domProps:{"innerHTML":_vm._s(_vm.highlightMatches(license.email))}}),_c('td',{class:!license.active
              ? 'table__cell table__cell--key table__cell--inactive'
              : 'table__cell table__cell--key',domProps:{"innerHTML":_vm._s(_vm.highlightMatches(license.id))}}),(!license.active)?_c('td',[_c('button',{staticClass:"licenses__button licenses__button--destroy",on:{"click":function($event){return _vm.handleDeleteKey(license)}}},[_c('span',[_vm._v("Delete Key")])])]):_c('td',{class:!license.active
              ? 'table__cell table__cell--copy table__cell--inactive'
              : 'table__cell table__cell--copy'},[_c('button',{staticClass:"licenses__button",attrs:{"disabled":!license.active},on:{"click":function($event){return _vm.handleCopy(license.id)}}},[(_vm.copied == license.id)?_c('span',[_vm._v("Copied!")]):_c('span',[_vm._v("Copy Key")])])])])}),0):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"licenses__header"},[_c('h3',{staticClass:"licenses__heading"},[_vm._v("Enterprise License Keys")]),_c('p',{staticClass:"licenses__description"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"licenses__toggle"},[_vm._v("Active")]),_c('th',[_vm._v("Email Address")]),_c('th',{staticClass:"licenses__key"},[_vm._v("License Key")]),_c('th',{staticClass:"licenses__copy"})])])}]

export { render, staticRenderFns }